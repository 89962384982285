@use "../../styles/mixins" as *;

.logcard {
    background-color: #e6eaf0;
    width: 100%;
    border-radius: 10px;
    margin: 1rem 10% 0rem 10%;
    color: 	#353839;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 
    3px 3px 8px 0 rgba(0, 0, 0, 0.18),
    -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
        flex-direction: row;
        justify-content: space-around;
    }

    &__content {
        display: flex;
        flex-direction: column;

        @include tablet {
            width: 40%;
        }
    }

    &__title {
        @include tablet {
            width: 20rem;
        }
    }
}