.edit {
    background-color: #e6eaf0;
    border-radius: 10px;
    margin: 1rem 10% 0rem 10%;
    color: 	#353839;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 
    3px 3px 8px 0 rgba(0, 0, 0, 0.18),
    -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__form {
        padding: 1rem;
        width: 100%;
        gap: 1rem;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &--label {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &--input {
            width: 100%;
            margin-top: 0.5rem;
            background-color: #e6eaf0;
            border-radius: 10px;
            padding: 0.5rem;
            border: none;
            outline:none;
            color:#353839;
            border: 3px solid #e6eaf0;
            box-shadow: 
            inset 5px 5px 10px 0 rgba(0, 0, 0, 0.1),
            inset -5px -5px 10px 0 rgba(255, 255, 255, 0.8),
            3px 3px 7px 0 rgba(0, 0, 0, 0.1),
            -3px -3px 7px 0 rgba(255, 255, 255, 0.8);
            &:focus {
                border: 3px solid #00A9E6;
            }
        }
    }
}

.edit-interval {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;

    &__option {
        width: 2rem;
        background-color: #e6eaf0;
        border-radius: 10px;
        padding: 0.5rem;
        border: none;
        color:#353839;
        border: 3px solid #e6eaf0;
        outline: none;
        box-shadow: 
        inset 5px 5px 10px 0 rgba(0, 0, 0, 0.1),
        inset -5px -5px 10px 0 rgba(255, 255, 255, 0.8),
        3px 3px 8px 0 rgba(0, 0, 0, 0.18),
        -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
        &:focus {
            border: 3px solid #00A9E6;
        }
    }

    &__label {
        display: flex;
        gap: 0.5rem;
        align-items: center;
    }

    &__select {
        background-color: #e6eaf0;
        border-radius: 10px;
        padding: 0.5rem;
        border: none;
        outline: none;
        color:#353839;
        border: 3px solid #e6eaf0;
        box-shadow: 
        3px 3px 8px 0 rgba(0, 0, 0, 0.18),
        -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
        &:focus {
            border: 3px solid #00A9E6;
        }
    }

    &__container {
        display: flex;
        gap: 0.5rem;
    }
}

.edit__form--submit, .edit__form--cancel{
    text-align: center;
    width: 100%;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 10px;
    background-color: #e6eaf0;
    color:#353839;
    font-size: 1rem;
    border: 3px solid #e6eaf0;
    box-shadow: 
    3px 3px 8px 0 rgba(0, 0, 0, 0.18),
    -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
    &:hover {
        box-shadow: 
            inset 5px 5px 10px 0 rgba(0, 0, 0, 0.1),
            inset -5px -5px 10px 0 rgba(255, 255, 255, 0.8),
            1px 1px 5px 0 rgba(0, 0, 0, 0.18),
            -1px -1px 5px 0 rgba(255, 255, 255, 0.8);
        }
}

