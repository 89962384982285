
@use "../../styles/mixins" as *;
.med__image {
    width: 2rem;
    margin: -0.8rem;

    @include tablet {
        width: 4rem;
    }
}

.med {
    width: 100%;
    background-color: #e6eaf0;
    border-radius: 10px;
    margin: 1rem 10% 0rem 10%;
    color: 	#353839;
    padding: 1rem;
    gap: 0.5rem;
    box-shadow: 
    3px 3px 8px 0 rgba(0, 0, 0, 0.18),
    -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    &__top {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @include tablet{
            width: 20%;
            margin-top: -1rem;
        }

    }

    &__title {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap:0.5rem;
    }

    &__info {
        padding: 1rem;
        border-radius: 10px;
        min-width: 10rem;

        &--cont {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        }

    &__form {
        width: 90%;

        @include tablet {
            display: flex;
            gap: 1rem;
            width: 100%;
            align-items: center;
           
        }
    }

    &__button {
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 10px;
        background-color: #e6eaf0;
        color:#353839;
        font-size: 1rem;
        border: 3px solid #e6eaf0;
        box-shadow: 
        3px 3px 8px 0 rgba(0, 0, 0, 0.18),
        -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
        &:hover {
        box-shadow: 
            inset 5px 5px 10px 0 rgba(0, 0, 0, 0.1),
            inset -5px -5px 10px 0 rgba(255, 255, 255, 0.8),
            1px 1px 5px 0 rgba(0, 0, 0, 0.18),
            -10px -10px 20px 0 rgba(255, 255, 255, 1);
        }

        @include tablet {
            min-width: 6rem;
        }
    }

    &__button--holder {
        margin-top: 0.5rem;
        display: flex;
        justify-content: center;

        
    }

    &__comment {
        width: 100%;

        
    }

    &__comment--input {
        width: 100%;
        margin: 0.5rem 0rem;
        background-color: #e6eaf0;
        border-radius: 10px;
        padding: 0.5rem;
        border: none;
        color:#353839;
        border: 3px solid #e6eaf0;
        outline: none;
        resize: none;
        box-shadow: 
        inset 5px 5px 10px 0 rgba(0, 0, 0, 0.1),
        inset -5px -5px 10px 0 rgba(255, 255, 255, 0.8),
        3px 3px 8px 0 rgba(0, 0, 0, 0.18),
        -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
        &:focus {
            border: 3px solid #00A9E6;
        }

        @include tablet {
            height: 6rem;
            min-width: 10rem;
        }
    }
}

.edit__delete {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    width: 90%;

    @include tablet {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }
}

.edit__button, .delete__button {
    text-align: center;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 10px;
    background-color: #e6eaf0;
    color:#353839;
    font-size: 1rem;
    border: 3px solid #e6eaf0;
    box-shadow: 
    3px 3px 8px 0 rgba(0, 0, 0, 0.18),
    -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
    &:hover {
        box-shadow: 
            inset 5px 5px 10px 0 rgba(0, 0, 0, 0.1),
            inset -5px -5px 10px 0 rgba(255, 255, 255, 0.8),
            1px 1px 5px 0 rgba(0, 0, 0, 0.18),
            -10px -10px 20px 0 rgba(255, 255, 255, 1);
        }
}

.red{
    color: red;
}

.edit__button{
    background-image: url("../../assets/images/icons8-edit.svg");
    background-position: center;
    background-repeat: no-repeat;
}

.delete__button{
    background-image: url("../../assets/images/icons8-delete.svg");
    background-position: center;
    background-repeat: no-repeat;
}