*,::before,::after{
    box-sizing: border-box;
    -webkit-appearance: none;
}

body{
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #e6eaf0;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4 {
    margin: 0;
}

textarea {
    font-size: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #e6eaf0;
    border-radius: 10px;
    border: none;
}
input {
    font-size: 1rem;
}

