@use "../../styles/mixins" as *;

.rotate{
    width:80px;
    height:80px;
    animation: rotation infinite 3s linear;
 }

.register {
    background-color: #e6eaf0;
    border-radius: 10px;
    margin: 1rem 10% 0rem 10%;
    color: 	#353839;
    padding: 1rem;
    gap: 1rem;
    box-shadow: 
    3px 3px 8px 0 rgba(0, 0, 0, 0.18),
    -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: column;
    align-items: center;

    &__top {
        width: 90%;
        background-color: #e6eaf0;
        border-radius: 10px;
        color: 	#353839;
        padding: 1rem;
        box-shadow: 
        3px 3px 8px 0 rgba(0, 0, 0, 0.18),
        -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__welcome {
        text-align: center;
    }

    &__logo{
        width: 5rem;
    }

    &__form {
        width: 90%;
        background-color: #e6eaf0;
        border-radius: 10px;
        color: 	#353839;
        padding: 1rem;
        gap: 0.5rem;
        // box-shadow: 
        // 3px 3px 8px 0 rgba(0, 0, 0, 0.18),
        // -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
        display: flex;
        flex-direction: column;
        align-items: center;

        @include tablet {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 2rem;
            
        }

        &--label {
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @include tablet {
                width: 30%;
            }
        }

        &--input {
            margin-top: 0.5rem;
            background-color: #e6eaf0;
            border-radius: 10px;
            padding: 0.5rem;
            border: none;
            outline: none;
            color:#353839;
            border: 3px solid #e6eaf0;
            box-shadow: 
            inset 5px 5px 10px 0 rgba(0, 0, 0, 0.1),
            inset -5px -5px 10px 0 rgba(255, 255, 255, 0.8),
            3px 3px 8px 0 rgba(0, 0, 0, 0.18),
            -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
            &:focus {
                border: 3px solid #00A9E6;
            }

            
        }

        &--button {
            margin-top: 0.5rem;
            padding: 0.5rem 1rem;
            border: none;
            border-radius: 10px;
            background-color: #e6eaf0;
            color:#353839;
            font-size: 1rem;
            border: 3px solid #e6eaf0;
            box-shadow: 
            3px 3px 8px 0 rgba(0, 0, 0, 0.18),
            -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
            &:hover {
                box-shadow: 
                    inset 5px 5px 10px 0 rgba(0, 0, 0, 0.1),
                    inset -5px -5px 10px 0 rgba(255, 255, 255, 0.8),
                    1px 1px 5px 0 rgba(0, 0, 0, 0.18),
                    -10px -10px 20px 0 rgba(255, 255, 255, 1);
                }


            @include tablet {
                width: 30%;
            }
        }
    }
}

.error {
    border: 3px solid red;
}

.error-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include tablet {
        flex-direction: row;
        width: 90%;
        justify-content: center;
        margin-top: 0.5rem;
        align-items: center;
        gap: 2rem;
    }
}