@use "../../styles/mixins" as *;
.none {
    display: none;
    position: relative;
}

.show {
    display: block;
}

.home {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__card {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include tablet {
            
            flex-direction: row;
            flex-wrap: wrap;
            flex: 45% 2;
            justify-content: center;
        
        }
    }

    &__add--button {
        margin: 0.5rem;
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 10px;
        background-color: #e6eaf0;
        color:#353839;
        font-size: 1rem;
        border: 3px solid #e6eaf0;
        box-shadow: 
        3px 3px 8px 0 rgba(0, 0, 0, 0.18),
        -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
        &:hover {
        box-shadow: 
            inset 5px 5px 10px 0 rgba(0, 0, 0, 0.1),
            inset -5px -5px 10px 0 rgba(255, 255, 255, 0.8),
            1px 1px 5px 0 rgba(0, 0, 0, 0.18),
            -10px -10px 20px 0 rgba(255, 255, 255, 1);
        }
    }

    &__log--button {
        margin: 0.5rem;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 10px;
        background-color: #e6eaf0;
        color:#353839;
        font-size: 1rem;
        border: 3px solid #e6eaf0;
        box-shadow: 
        3px 3px 8px 0 rgba(0, 0, 0, 0.18),
        -3px -3px 8px 0 rgba(255, 255, 255, 0.8);
        &:hover {
        box-shadow: 
            inset 5px 5px 10px 0 rgba(0, 0, 0, 0.1),
            inset -5px -5px 10px 0 rgba(255, 255, 255, 0.8),
            1px 1px 5px 0 rgba(0, 0, 0, 0.18),
            -10px -10px 20px 0 rgba(255, 255, 255, 1);
        }
    }
}